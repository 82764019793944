import React from "react";
import Menu from "./Menu";
import "./PageTemplate.css";
import Footer from "./Footer";

const PageTemplate = ({ title, subtitle, children, showHeader = true }) => {
  return (
    <div className="page-template">
      <Menu />
      {showHeader && (
        <>
          {/* Header Section */}
          <section className="page-header">
            <div className="header-content">
              <h1>{title}</h1>
              <p>{subtitle}</p>
            </div>

            <div className="animated-background">
              {[...Array(10)].map((_, i) => (
                <i
                  key={i}
                  className={`fas fa-${
                    [
                      "heartbeat",
                      "prescription",
                      "stethoscope",
                      "pills",
                      "hospital",
                    ][i % 5]
                  } medical-symbol floating-icon`}
                  style={{
                    top: `${Math.random() * 100}%`,
                    left: `${Math.random() * 100}%`,
                    fontSize: `${Math.random() * 2 + 1}rem`,
                    animationDelay: `${Math.random() * 2}s`,
                  }}
                />
              ))}
            </div>
          </section>
        </>
      )}
      {/* Main Content */}
      <main className="page-content">{children}</main>
      <Footer />
    </div>
  );
};

export default PageTemplate;
