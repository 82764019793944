import React from "react";
import { trackEvent } from "../services/analytics";

export default function Footer() {
  return (
    <>
      {/* Footer Section */}
      {/* Footer */}
      <footer className="page-footer">
        <div className="footer-content">
          <div className="footer-sections">
            <div className="footer-links">
              <a
                href="/about"
                onClick={() => trackEvent("Footer", "Click", "About")}
              >
                About
              </a>
              <a
                href="/contact"
                onClick={() => trackEvent("Footer", "Click", "Contact")}
              >
                Contact
              </a>
              <a
                href="/pricing"
                onClick={() => trackEvent("Footer", "Click", "Pricing")}
              >
                Pricing
              </a>
              <a
                href="/providers"
                onClick={() => trackEvent("Footer", "Click", "Providers")}
              >
                Providers
              </a>
              <a
                href="/features"
                onClick={() => trackEvent("Footer", "Click", "Features")}
              >
                Features
              </a>
              {/* <a href="/developers">Developers</a> */}
            </div>
            <div className="footer-links">
              <a href="/privacy">Privacy</a>
              <a href="/terms">Terms</a>
            </div>
            <div className="footer-contact">
              <div>
                <i className="fas fa-home"></i> &nbsp; VALID DOT CARE, Inc
              </div>

              <div>
                <i className="fas fa-envelope"></i> &nbsp; support@valid.care
              </div>
              <div>
                <i className="fas fa-map-marker-alt"></i> &nbsp; Aurora, NE
                68818
              </div>
            </div>
          </div>
          {/* <div className="footer-social">
          <a
            href="https://twitter.com/loginhealth"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter fa-sm"></i>
          </a>
          <a
            href="https://linkedin.com/company/loginhealth"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin fa-sm"></i>
          </a>
          <a href="mailto:contact@login.health">
            <i className="fas fa-envelope fa-sm"></i>
          </a>
        </div> */}
          <div className="footer-bottom">
            <p>&copy; 2025 Login.health</p>
          </div>
        </div>
      </footer>
    </>
  );
}
