import React from "react";
import PageTemplate from "./PageTemplate";
import "./Download.css";

const Download = () => {
  return (
    <PageTemplate
      title="App Download"
      subtitle="Access your health records on the go"
    >
      <div className="download-container">
        <div className="download-content">
          <div className="app-preview">
            <img src="/images/hero.png" alt="Login.health App Preview" />
          </div>

          <div className="download-options">
            <h2>Get the App</h2>
            <p>Download Login.health for iOS or Android</p>

            <div className="store-buttons">
              <a
                href="https://apps.apple.com/us/app/login-health/id6717607977"
                target="_blank"
                rel="noopener noreferrer"
                className="store-button apple"
              >
                <i
                  style={{
                    marginTop: 16,
                  }}
                  className="fab fa-apple"
                ></i>
                <div className="button-text">
                  <strong>App Store</strong>
                </div>
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=app.login.health&pli=1"
                target="_blank"
                rel="noopener noreferrer"
                className="store-button google"
              >
                <i
                  style={{
                    marginTop: 16,
                  }}
                  className="fab fa-google-play"
                ></i>
                <div className="button-text">
                  <strong>Google Play</strong>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 60 }}>
          <div className="app-features">
            <div className="feature">
              <i className="fas fa-lock"></i>
              <h3>Secure Access</h3>
              <p>Your data is protected with enterprise-grade security</p>
            </div>

            <div className="feature">
              <i className="fas fa-sync"></i>
              <h3>Real-time Sync</h3>
              <p>Access your latest health records instantly</p>
            </div>

            <div className="feature">
              <i className="fas fa-share-alt"></i>
              <h3>Easy Sharing</h3>
              <p>Share records with healthcare providers seamlessly</p>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default Download;
