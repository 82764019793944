import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { trackEvent } from "../services/analytics";
import Slider from "react-slick";
import StoryPath from "./StoryPath";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Menu from "./Menu";
import "./HomePage.css";
import "./animations.css";
import Footer from "./Footer";

const HomePage = () => {
  return (
    <div className="homepage">
      <Menu />
      <StoryPath />
      {/* Hero Section */}
      <section className="hero line1">
        <div className="animated-background">
          {[...Array(15)].map((_, i) => (
            <motion.i
              key={i}
              className={`fas fa-${
                [
                  "heartbeat",
                  "prescription",
                  "stethoscope",
                  "pills",
                  "hospital",
                ][i % 5]
              } medical-symbol floating-icon`}
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                fontSize: `${Math.random() * 2 + 1}rem`,
                animationDelay: `${Math.random() * 2}s`,
              }}
            />
          ))}
        </div>
        <motion.div className="hero-content">
          <h1>
            <span style={{ color: "#000" }}>Your</span> <br /> Personal Health
            Records{" "}
          </h1>
          <p
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.2, delay: 0.6 }}
            style={{ fontWeight: 700, maxWidth: "400px" }}
          >
            Access, manage, and share your personal health records securely from
            anywhere with anyone!
          </p>
          <button
            className="cta-button"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            onClick={() => {
              window.location.href = "/waitlist";
            }}
          >
            Get Started
          </button>
        </motion.div>
        <div className="hero-image-container">
          <img
            src="/images/hero.png"
            alt="Healthcare Dashboard"
            className="hero-image"
          />
        </div>
      </section>

      {/* Explainer Video Section */}
      <section className="explainer-video">
        <h2 style={{ textAlign: "center" }}>How It Works</h2>
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/XOs8yE63RwA?rel=0&controls=0&showinfo=0"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      {/* Logo Wall */}
      {/* <section className="logo-wall">
        <div className="logo-wall-container">
          <div className="logo-item">
            <img
              src="https://via.placeholder.com/150x50?text=Hospital+1"
              alt="Hospital Partner 1"
            />
          </div>
          <div className="logo-item">
            <img
              src="https://via.placeholder.com/150x50?text=Clinic+2"
              alt="Clinic Partner 2"
            />
          </div>
          <div className="logo-item">
            <img
              src="https://via.placeholder.com/150x50?text=Healthcare+3"
              alt="Healthcare Partner 3"
            />
          </div>
          <div className="logo-item">
            <img
              src="https://via.placeholder.com/150x50?text=Medical+4"
              alt="Medical Partner 4"
            />
          </div>
          <div className="logo-item">
            <img
              src="https://via.placeholder.com/150x50?text=Health+5"
              alt="Health Partner 5"
            />
          </div>
        </div>
      </section> */}

      {/* How It Works Section */}
      <section className="how-it-works line2">
        <div className="steps-container">
          <div className="step">
            <div className="step-icon">
              <i className="fas fa-user-plus"></i>
            </div>
            <h3>Create Your Account</h3>
            <p>Sign up in minutes with our secure verification process</p>
            <ul className="step-details">
              <li>Quick email verification</li>
              <li>Two-factor authentication setup</li>
              <li>Personalized profile creation</li>
            </ul>
          </div>

          <div className="step">
            <div className="step-icon">
              <i className="fas fa-upload"></i>
            </div>
            <h3>Import Records</h3>
            <p>Easily import your existing medical records and documents</p>
            <ul className="step-details">
              <li>Direct provider integration</li>
              <li>Document scanning feature</li>
              <li>Automatic data organization</li>
            </ul>
          </div>

          <div className="step">
            <div className="step-icon">
              <i className="fas fa-check-circle"></i>
            </div>
            <h3>Instant Check-In</h3>
            <p>No more waiting room paperwork</p>
            <ul className="step-details">
              <li>Real-time provider check-in</li>
              <li>Choose what records to share</li>
              <li>Secure provider network</li>
            </ul>
          </div>

          <div className="step">
            <div className="step-icon">
              <i className="fas fa-share-square"></i>
            </div>
            <h3>Share Securely</h3>
            <p>Share records with healthcare providers when needed</p>
            <ul className="step-details">
              <li>One-tap provider sharing</li>
              <li>Temporary access links</li>
              <li>Access control settings</li>
            </ul>
          </div>

          <div className="step">
            <div className="step-icon">
              <i className="fas fa-bell"></i>
            </div>
            <h3>Stay Updated</h3>
            <p>Receive important notifications and reminders</p>
            <ul className="step-details">
              <li>Appointment reminders</li>
              <li>Medication schedules</li>
              <li>Health goal tracking</li>
            </ul>
          </div>

          <div className="step">
            <div className="step-icon">
              <i className="fas fa-mobile-alt"></i>
            </div>
            <h3>Access Anywhere</h3>
            <p>Your health information available 24/7 on any device</p>
            <ul className="step-details">
              <li>Mobile app access</li>
              <li>Cross-device sync</li>
              <li>Offline availability</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Network Section */}
      <section className="network ">
        <h2>Our Healthcare Network</h2>
        <div className="network-content">
          <p>Join the largest network of connected healthcare providers</p>
          <div className="network-stats">
            <div className="stat-item">
              <div className="stat-number">500+</div>
              <div className="stat-label">Hospitals & Clinics</div>
            </div>
            <div className="stat-item">
              <div className="stat-number">∞</div>
              <div className="stat-label">Unlimited Circle</div>
            </div>
            <div className="stat-item">
              <div className="stat-number">∞</div>
              <div className="stat-label">Personal Health Records</div>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Section */}
      <section className="why-choose line3">
        <Slider
          className="features-slider"
          dots={true}
          infinite={true}
          speed={500}
          slidesToShow={3}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={5000}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          <motion.div
            className="feature"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            <i className="fas fa-shield-alt"></i>
            <h3>Advanced Security</h3>
            <p>
              User-level encryption and HIPAA compliance ensure your data stays
              private
            </p>
            <ul className="feature-details">
              <li>Multi-factor authentication</li>
              <li>End-to-end encryption</li>
              <li>Simple yet secure</li>
            </ul>
          </motion.div>

          <motion.div
            className="feature"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <i className="fas fa-history"></i>
            <h3>Lifetime Health Record</h3>
            <p>Your complete medical history, always at your fingertips</p>
            <ul className="feature-details">
              <li>Comprehensive health timeline</li>
              <li>Digital document storage</li>
              <li>Automated record updates</li>
            </ul>
          </motion.div>

          <motion.div
            className="feature"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <i className="fas fa-bell"></i>
            <h3>Intelligent Alerts</h3>
            <p>Stay on top of your health with smart notifications</p>
            <ul className="feature-details">
              <li>Medication reminders</li>
              <li>Appointment scheduling</li>
              <li>Preventive care alerts</li>
            </ul>
          </motion.div>

          <motion.div
            className="feature"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <i className="fas fa-users"></i>
            <h3>Connected Care Circle</h3>
            <p>Share your health journey with trusted caregivers</p>
            <ul className="feature-details">
              <li>Family access controls</li>
              <li>Provider collaboration</li>
              <li>Emergency contact system</li>
            </ul>
          </motion.div>

          <motion.div
            className="feature"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <i className="fas fa-dna"></i>
            <h3>Family Health Insights</h3>
            <p>Understanding your health through generations</p>
            <ul className="feature-details">
              <li>Family health mapping</li>
              <li>Genetic risk awareness</li>
              <li>Hereditary condition tracking</li>
            </ul>
          </motion.div>

          <motion.div
            className="feature"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <i className="fas fa-mobile-alt"></i>
            <h3>Seamless Access</h3>
            <p>Your health information anywhere, anytime</p>
            <ul className="feature-details">
              <li>Cross-platform compatibility</li>
              <li>Offline access</li>
              <li>Real-time synchronization</li>
            </ul>
          </motion.div>
        </Slider>
      </section>

      {/* Mission Section */}
      <section className="mission-sections line4">
        <h2>Our Mission</h2>
        <div className="mission-content">
          <div className="mission-intro">
            <p>
              At Login.health, we're committed to revolutionizing how you manage
              your health information. Our mission is to create a seamless,
              secure, and accessible platform that puts you in control of your
              health journey.
            </p>
          </div>

          <div className="mission-item">
            <i className="fas fa-file-medical"></i>
            <h3>Complete Health History</h3>
            <p>
              We believe your health information should be fully comprehensive
              and instantly accessible. Our platform securely stores your entire
              medical journey, from routine check-ups to specialized treatments.
              With features like automated record organization and intelligent
              categorization, you'll have instant access to your complete health
              timeline, including medical records, lab results, prescriptions,
              and treatment plans.
            </p>
          </div>

          <div className="mission-item">
            <i className="fas fa-share-alt"></i>
            <h3>Seamless Information Sharing</h3>
            <p>
              Healthcare is a collaborative journey. That's why we've built
              sophisticated yet simple sharing capabilities into our platform.
              Share your health information securely with healthcare providers,
              family members, or caregivers with just a few taps. Our custom
              permission settings ensure you maintain control over who sees
              what, while our emergency access features provide peace of mind
              when it matters most.
            </p>
          </div>

          <div className="mission-item">
            <i className="fas fa-mobile-alt"></i>
            <h3>Always at Your Fingertips</h3>
            <p>
              Your health information should be immediately available whenever
              and wherever you need it. Our platform works seamlessly across all
              your devices, allowing for real-time updates and offline access
              capabilities. With 24/7 availability and cross-platform
              synchronization, you're always connected to your health
              information. Whether you're at home, traveling, or at your
              doctor's office, your complete health record is always just a tap
              away.
            </p>
          </div>
        </div>
      </section>

      {/* Bottom CTA Section */}
      <section className="bottom-cta line5">
        <div className="bottom-cta-content">
          <h2>
            Ready to Take Control of <br /> Your Health Journey?
          </h2>
          <p>
            Join the many users who are already taking back their health records
            and taking charge of their health story!
          </p>
          <div className="bottom-cta-buttons">
            <button
              onClick={() => {
                trackEvent("CTA", "Click", "Get Started - Hero");
                window.location.href = "/waitlist";
              }}
              className="cta-button"
            >
              Join the Waitlist
            </button>
            <button
              onClick={() => {
                trackEvent("CTA", "Click", "Provider Signup - Bottom");
                window.location.href = "/providers";
              }}
              className="cta-button"
            >
              I'm a provider
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default HomePage;
